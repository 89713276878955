import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";
import * as moment from "moment";

import { AccommodationType, Resort, getMxtsEnv } from "../../mxts";
import { Address, Amenity, ApiCallOptions, AvailabilityResult, Document, Facet, ResortGroup, Resource, StayPeriodDef } from "@maxxton/cms-mxts-api";
import { WebContent as CmsApiWebContent, Site, Template, WithId } from "@maxxton/cms-api";
import { DATE_FORMAT, ResultStyleSelector } from "../../../utils/constants";
import { FetchParameters, FetchResult } from "../typesearchContainer/TypesearchContainerWidget";
import { LocalizedDcOptions, WidgetOptions } from "./";
import { RenderVirtualizedList, withVirtualization } from "../../../utils/virtualization/withVirtualization";
import { StateHandler, warmupState } from "../../../utils/cacheWarmup.util";
import { dispatchEmptyAction, generateRandomKey, getHideWidgetClass, isClientLoggedIn, isEqual, sortResults } from "../../../components/utils";
import { getDefaultStayCode, stayPeriodDefById } from "../../mxts/mxts.util";
import { getI18nLocaleString, wrapProps } from "../../../i18n";
import { getLocalizedContent, isMobileDeviceDetected } from "../../../utils/localizedContent.util";

import { ActionType } from "../../../redux/actions";
import { AmenitiesAction } from "../../../redux/actions/amenitiesAction";
import { AvailabilityAction } from "../../../redux/actions/availabilityAction";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { AvailabilityUtil } from "../../../utils/availability.util";
import { Button } from "reactstrap";
import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { CurrentLocale } from "../../../app.types";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { ErrorBoundary } from "../../../components/ErrorBoundary";
import { Resort as ExtendedResort } from "../../../plugins/mxts/index";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { Loader } from "../../../components/Loader";
import { MarkerDetails } from "../../page/map/mapWidget.types";
import ProgressBar from "../../../components/ProgressBar";
import { Sort } from "../../mxts/searchfacet/searchFacet.enum";
import { State } from "../../../redux";
import { UserInterfaceState } from "../../../redux/reducers/userInterfaceReducer";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { fetchResortsByResortIds } from "../../../utils/resort.util";
import { fontColorPicker } from "../../../utils/colorpicker.util";
import { getStayPeriodDefFilters } from "../../../utils/stayPeriodDefs.util";
import { isClientSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";
import { renderNoResultsFoundContent } from "../containerWidget.util";
import { renderPageWidgets } from "../../widget";
import { scroller } from "react-scroll";

export interface LocationSearchContainerProps extends LocationSearchContainerBaseProps, LocationSearchContainerStoreProps, LocationSearchContainerDispatchProps {}

interface LocationSearchContainerBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    classNames: string;
    options: WidgetOptions;
    context: CMSProvidedProperties;
    childrenList: JSX.Element[];
    childrenGrid: JSX.Element[];
    childrenMobile: JSX.Element[];
    childrenTablet: JSX.Element[];
    children: Array<{ element: JSX.Element; options: WidgetOptions }>;
    markerIds?: number[];
    showLoadMoreButton?: boolean;
    warmupState?: LocationSearchContainerState;
    renderVirtualizedList?: RenderVirtualizedList;
}

interface LocationSearchContainerStoreProps {
    dynamicFilter: DynamicFilter;
    availabilityState: AvailabilityState;
    userInterfaceState: UserInterfaceState;
}

interface LocationSearchContainerDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction | AvailabilityAction | AmenitiesAction>;
}

export interface LocationSearchContainerState {
    disableWidget: boolean;
    mapSynchedResorts?: Resort[] | undefined;
    onMouseOver: MarkerDetails[];
    highlightedMarker: number;
    zoomedResorts: number[];
    showMap: boolean;
    fetchingMoreLocations: boolean;
    fromIndex: number;
    toIndex: number;
    minPrice?: number;
    maxPrice?: number;
    infiniteLoading: boolean;
    totalResorts: number;
    deviceType: string;
    resortId?: number | null;
    directSearchInput?: string;
    sortingOption: string;
    webContent?: (CmsApiWebContent & WithId) | null;
    template?: JSX.Element[] | null;
    errorFetchingResorts: string;
    resorts?: Resort[];
    stayPeriodDefs?: StayPeriodDef[];
    addresses?: Address[];
    fetchedResources?: Resource[];
    nextResorts?: number[];
    amenityCodes?: string[];
    isLoading: boolean;
    toggleChildren: string[];
}

type LocationSearchContainerStateHandler = StateHandler<LocationSearchContainerProps, LocationSearchContainerState>;

// eslint-disable-next-line max-len
export class LocationSearchContainerWidgetBase extends React.Component<LocationSearchContainerProps, LocationSearchContainerState> {
    private locationSearchScroll: HTMLDivElement | null;
    private buttonDOM: HTMLButtonElement | null;

    private controller: AbortController = new AbortController();

    public static async warmupCache(props: LocationSearchContainerProps): Promise<LocationSearchContainerState> {
        return warmupState(props, LocationSearchContainerWidgetBase.defaultState(props), async (stateHandler) => {
            props.dynamicFilter = props.context.reduxStore.store.getState().dynamicFilter;

            props.availabilityState = await AvailabilityUtil.getAvailabilityByDynamicFilter(props.dynamicFilter, undefined, props.context);
            await LocationSearchContainerWidgetBase.updateLocations(props, true, stateHandler);

            if (props.dynamicFilter.amenities) {
                await LocationSearchContainerWidgetBase.handleAmenities(props.dynamicFilter.amenities, stateHandler);
            }
        });
    }

    public static async initDefaultFilter(props: LocationSearchContainerProps): Promise<void> {
        await LocationSearchContainerWidgetBase.populateDynamicFilterWithPreSelectedFilters(props);
    }

    private static defaultState(props: LocationSearchContainerProps): LocationSearchContainerState {
        return {
            // availableResources: [],
            disableWidget: true,
            onMouseOver: [],
            highlightedMarker: -1,
            zoomedResorts: [],
            showMap: true,
            fetchingMoreLocations: false,
            fromIndex: 0,
            toIndex: +props.options.defaultNumberOfResorts! || 5,
            infiniteLoading: true,
            totalResorts: 0,
            deviceType: "",
            sortingOption: props.dynamicFilter.sortingOption || Sort[Sort.highToLowRating],
            errorFetchingResorts: "",
            isLoading: true,
            resorts: [],
            toggleChildren: [],
        };
    }

    constructor(props: LocationSearchContainerProps) {
        super(props);
        this.state = {
            ...LocationSearchContainerWidgetBase.defaultState(props),
            ...(props.warmupState || {}),
        };
        this.renderLocationSearchPanel = this.renderLocationSearchPanel.bind(this);
        // LocationSearchContainerWidgetBase.populateDynamicFilterWithPreSelectedFilters(props);
    }

    public componentDidMount() {
        const { availabilityState, dynamicFilter } = this.props;
        if (availabilityState?.availabilityResult?.response?.resortGroup?.length) {
            LocationSearchContainerWidgetBase.updateLocations(this.props, true, this, this);
        }
        this.setState({ disableWidget: !isClientLoggedIn() });
        const { options } = this.props;
        if (!options.showLoadMoreButton) {
            window.addEventListener("scroll", this.handleScroll);
        }
        if (options.resultsPanelMobile && screen.width < 768) {
            this.setState({ deviceType: "mobile" });
        } else if (options.resultsPanelTablet && screen.width > 767 && screen.width < 1025) {
            this.setState({ deviceType: "tablet" });
        }
        if (dynamicFilter.amenities) {
            LocationSearchContainerWidgetBase.handleAmenities(dynamicFilter.amenities, this, this);
        }

        dispatchEmptyAction(this.props.dispatchAction);
    }

    public componentDidUpdate(prevProps: Readonly<LocationSearchContainerProps>, prevState: Readonly<LocationSearchContainerState>) {
        const {
            options: { enableVirtualization },
            userInterfaceState: { resultLayoutViewOptions },
        } = this.props;
        const displayGridListMap = resultLayoutViewOptions?.resultLayoutDisplayType;
        const { mapSynchedResorts, fromIndex, toIndex } = this.state;
        const { mapSynchedResorts: prevMapSynchedResorts, fromIndex: prevFromIndex, toIndex: prevToIndex } = prevState;
        const slicedResorts = mapSynchedResorts?.slice(fromIndex, toIndex) || [];
        const prevSlicedResorts = prevMapSynchedResorts?.slice(prevFromIndex, prevToIndex) || [];
        const results: Element | null = document.querySelector(".search-results-wrapper .location-search-wrapper");
        const map: Element | null = document.querySelector(".map-wrapper");
        const hasChild: NodeListOf<Element> = document.querySelectorAll(".list-grid-map-view");
        if (hasChild?.length) {
            Array.from(hasChild).forEach((child) => {
                if (displayGridListMap === "list-view" || displayGridListMap === "grid-view") {
                    if (child?.contains(results as any) === false) {
                        child.classList.add("d-none");
                    } else {
                        child.classList.remove("d-none");
                    }
                } else if (displayGridListMap === "list-view-map" || displayGridListMap === "grid-view-map") {
                    if (child?.contains(results as any) || child?.contains(map as any)) {
                        child.classList.remove("d-none");
                    }
                }
            });
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<LocationSearchContainerProps>) {
        const response: AvailabilityResult | undefined = this.props.availabilityState.availabilityResult;
        const nextResponse: AvailabilityResult | undefined = nextProps.availabilityState?.availabilityResult;
        const env: ApiCallOptions | undefined = nextProps.availabilityState.env;
        const { maxPrice, minPrice, mapSynchedResorts, resortId, resorts } = this.state;
        const resultLayoutDisplayType = nextProps.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType;
        const { minprice, maxprice, amenities, markerIds, selectedDirectSearchId, directSearchInput, sortingOption } = nextProps.dynamicFilter;

        if (nextResponse && env) {
            const nextResorts: ResortGroup | undefined = nextResponse.response.resortGroup || undefined;
            const resorts: ResortGroup | undefined = response?.response.resortGroup || undefined;
            if (nextResorts && !isEqual(nextResorts, resorts)) {
                this.setState({ isLoading: true }, () => {
                    LocationSearchContainerWidgetBase.updateLocations(nextProps, true, this, this);
                });
            }
        }

        let zoomedResorts: number[] = this.state.zoomedResorts;
        let totalResorts: number = mapSynchedResorts?.length || 0;
        if (markerIds && zoomedResorts !== markerIds) {
            zoomedResorts = markerIds;
        }
        if (resultLayoutDisplayType !== undefined) {
            totalResorts = resorts?.length || mapSynchedResorts?.length || 0;
        }

        this.setState(
            {
                totalResorts,
                zoomedResorts,
            },
            () => {
                if (resultLayoutDisplayType === "list-view-map" || resultLayoutDisplayType === "grid-view-map") {
                    this.refreshResorts(this.state.zoomedResorts);
                }
            }
        );

        if (resultLayoutDisplayType !== undefined) {
            totalResorts = resorts?.length || totalResorts || 0;
        }

        if (selectedDirectSearchId !== null && resortId !== selectedDirectSearchId) {
            this.setState({ resortId: selectedDirectSearchId }, () => {
                LocationSearchContainerWidgetBase.updateLocations(nextProps, undefined, this, this);
            });
        }

        if (directSearchInput !== undefined && this.state.directSearchInput !== directSearchInput) {
            this.setState({ directSearchInput }, () => {
                LocationSearchContainerWidgetBase.updateLocations(nextProps, undefined, this, this);
            });
        }

        if (sortingOption && this.state.sortingOption !== sortingOption) {
            this.setState({ sortingOption }, () => this.handleSortingSelect(sortingOption));
        }

        if ((minPrice && minPrice !== minprice) || (maxPrice && maxPrice !== maxprice)) {
            this.setState({ minPrice: minprice }, () => {
                LocationSearchContainerWidgetBase.updateLocations(nextProps, undefined, this, this);
            });
        }

        if (amenities && !isEqual(this.props.dynamicFilter.amenities, amenities)) {
            LocationSearchContainerWidgetBase.handleAmenities(amenities, this, this);
        }
    }

    public componentWillUnMount() {
        this.controller.abort();
        window.removeEventListener("scroll", this.handleScroll);
    }

    public shouldComponentUpdate(nextProps: Readonly<LocationSearchContainerProps>, nextState: Readonly<LocationSearchContainerState>): boolean {
        return (
            !isEqual(this.state, nextState) ||
            !isEqual(this.props.dynamicFilter, nextProps.dynamicFilter) ||
            nextState.errorFetchingResorts !== this.state.errorFetchingResorts ||
            this.props.availabilityState.fetching !== nextProps.availabilityState.fetching
        );
    }

    public render(): JSX.Element | null {
        const {
            options,
            context,
            userInterfaceState: { resultLayoutViewOptions },
            renderVirtualizedList,
        } = this.props;
        const { currentLocale, site } = context;
        const { resultLayoutDisplayType } = resultLayoutViewOptions || {};
        const { resorts, mapSynchedResorts, fromIndex, toIndex, totalResorts, deviceType, disableWidget } = this.state;
        const hideWidget: string | null = getHideWidgetClass(this.props.options, disableWidget);
        const slicedResorts = mapSynchedResorts?.slice(fromIndex, toIndex) || [];

        if (hideWidget === null) {
            return null;
        }
        let displayTypeContainer = "";
        let displayTypeChild = "";
        let childrenArray: JSX.Element[] = [];
        const filterRegExp = new RegExp("\\$filteredCount");
        const totalRegExp = new RegExp("\\$totalCount");
        if (resultLayoutDisplayType === "grid-view" || resultLayoutDisplayType === "grid-view-map" || (resultLayoutDisplayType === undefined && options.resultsPanelGrid)) {
            childrenArray = this.props.childrenGrid;
            displayTypeContainer = "row";
            // eslint-disable-next-line max-len
            displayTypeChild = `${options.columns ? "col-sm-" + options.columns : ""} ${options.columnsResp ? "col-" + options.columnsResp : "col"} ${
                options.columnsTab ? "col-md-" + options.columnsTab : ""
            } ${options.columnsDesk ? "col-lg-" + options.columnsDesk : ""}`;
        } else if (deviceType === "mobile") {
            childrenArray = this.props.childrenMobile;
        } else if (deviceType === "tablet") {
            childrenArray = this.props.childrenTablet;
        } else {
            childrenArray = this.props.childrenList;
        }
        if (resultLayoutDisplayType === "map-view") {
            return null;
        }
        if (isClientSide()) {
            if (resorts?.length) {
                if (slicedResorts.length) {
                    let resultCount = "";
                    const filteredIndex = toIndex < totalResorts ? toIndex : totalResorts;
                    if (mapSynchedResorts && totalResorts && (!options.resultStyleSelector || options.resultStyleSelector === ResultStyleSelector.DEFAULT)) {
                        resultCount = getI18nLocaleString(namespaceList.genericCrud, "showingCountValue", currentLocale, site)
                            .replace(filterRegExp, filteredIndex.toString())
                            .replace(totalRegExp, totalResorts.toString());
                    } else if (options.resultStyleSelector === ResultStyleSelector.SHOWING_X_RESULT) {
                        resultCount = getI18nLocaleString(namespaceList.genericCrud, "showingCountValueStyle1", currentLocale, site).replace(filterRegExp, filteredIndex.toString());
                    } else {
                        resultCount = getI18nLocaleString(namespaceList.genericCrud, "showingCountValueStyle2", currentLocale, site).replace(filterRegExp, filteredIndex.toString());
                    }
                    return (
                        <div
                            className={`search-results-wrap ${hideWidget} location-search`}
                            ref={(input) => {
                                this.locationSearchScroll = input;
                            }}
                        >
                            {options?.resultStyleSelector !== ResultStyleSelector.DISPLAY_STYLE_HIDE && <div className="result-count">{resultCount}</div>}
                            <div
                                // eslint-disable-next-line max-len
                                className={`search-results-wrapper location-search-wrapper ${resultLayoutDisplayType || ""} ${displayTypeContainer}`}
                            >
                                {childrenArray.length > 0 && options.enableVirtualization && renderVirtualizedList
                                    ? renderVirtualizedList(slicedResorts, this.renderLocationSearchPanel, { childrenArray, displayType: displayTypeChild })
                                    : this.renderUnvirtualizedList({ childrenArray, slicedResorts, displayTypeChild })}
                            </div>
                            {this.getLoadMoreButton()}
                        </div>
                    );
                }
                return (
                    <div className="unit-search no-results">
                        <h4 className="no-type-results-header">{getI18nLocaleString(namespaceList.widgetMap, "tryAgain", currentLocale, site)}</h4>
                        <p className="no-type-results-body">{getI18nLocaleString(namespaceList.widgetMap, "noResults", currentLocale, site)}</p>
                    </div>
                );
            } else if (this.state.errorFetchingResorts) {
                return <div className={`${hideWidget}`}>{renderNoResultsFoundContent({ noResultsFoundWebContent: this.state.webContent, noResultsFoundTemplate: this.state.template, context })}</div>;
            }
        }
        return <Loader type="locationSearchContainer" />;
    }
    private renderToggleIcon = (name: string) => {
        const { options } = this.props;
        const { iconColor, iconToRight } = options;
        return (
            <FontAwesome
                name={name}
                className={`fontawesome-${name} icon ${iconToRight ? "ml-2" : "mr-1"} ${fontColorPicker(iconColor)}`}
                style={{ color: iconColor?.includes("rgba") ? iconColor : undefined }}
            />
        );
    };
    private getLoadMoreButton() {
        const {
            context: { currentLocale, site },
            childrenList,
            childrenGrid,
            childrenMobile,
            childrenTablet,
            options,
        } = this.props;
        const { resorts, mapSynchedResorts, fetchingMoreLocations, infiniteLoading, toIndex } = this.state;
        return (
            <div>
                {options.showLoadMoreButton &&
                resorts &&
                mapSynchedResorts &&
                (childrenGrid.length || childrenList.length || childrenMobile.length || childrenTablet.length) &&
                toIndex < mapSynchedResorts.length ? (
                    <div className="panel text-center">
                        <Button
                            onClick={this.fetchNextItems}
                            className="button button--l  button--secondary book-btn"
                            innerRef={(buttonDOM: any) => {
                                this.buttonDOM = buttonDOM;
                            }}
                        >
                            <FontAwesome name="spinner" className={classNames("searchfacet-progress", fetchingMoreLocations ? "in-progress" : "no-progress")} />
                            {" " + getI18nLocaleString(namespaceList.widgetTypeSearch, "fetchNextItems", currentLocale, site)}
                            <FontAwesome name="spinner" className={"searchfacet-progress no-progress"} />
                        </Button>
                    </div>
                ) : null}
                {!options.showLoadMoreButton && infiniteLoading ? <ProgressBar /> : ""}
            </div>
        );
    }

    private toggleTypes = (key: string): void => {
        const { toggleChildren } = this.state;
        const { dispatchAction, options } = this.props;
        const visibleTypeContainers = [...toggleChildren];
        if (visibleTypeContainers.includes(key)) {
            visibleTypeContainers.splice(visibleTypeContainers.indexOf(key), 1);
            scroller.scrollTo(key, {
                duration: 500,
                delay: 0,
                smooth: "linear",
            });
        } else {
            visibleTypeContainers.push(key);
        }
        if (options.showResortAmenitiesCount && visibleTypeContainers.length === 0) {
            const amenities = this.props.availabilityState.availabilityResult?.response.amenities;
            if (amenities?.length) {
                const action: AmenitiesAction = {
                    type: ActionType.AmenitiesFetch,
                    payload: amenities,
                };
                dispatchAction(action);
            }
        }
        this.setState({ toggleChildren: visibleTypeContainers });
    };

    private handleScroll = () => {
        const scrollTop = window.scrollY;
        const sidebarheight = this.locationSearchScroll?.offsetHeight || 0;
        const windowheight = window.innerHeight;
        if (scrollTop >= sidebarheight - windowheight) {
            this.fetchNextItems();
        }
    };

    private static setMinAndMaxResortsPrice(resorts: Resort[]) {
        if (!resorts.length) {
            return;
        }
        const sortedResorts = sortResults([...resorts!], Sort[Sort.highToLowPrice], undefined, true);
        const maxprice = sortedResorts[0].basePriceInclusive
            ? sortedResorts[0].basePriceInclusive
            : sortedResorts[0].nightPriceInclusive
            ? sortedResorts[0].nightPriceInclusive
            : sortedResorts[0].baseNightPriceInclusive
            ? sortedResorts[0].baseNightPriceInclusive
            : undefined;
        const minprice = sortedResorts[sortedResorts.length - 1].basePriceInclusive
            ? sortedResorts[sortedResorts.length - 1].basePriceInclusive
            : sortedResorts[sortedResorts.length - 1].nightPriceInclusive
            ? sortedResorts[sortedResorts.length - 1].nightPriceInclusive
            : sortedResorts[sortedResorts.length - 1].baseNightPriceInclusive
            ? sortedResorts[sortedResorts.length - 1].baseNightPriceInclusive
            : undefined;
        return { minprice, maxprice };
    }

    private refreshResorts = (zoomedResorts: number[]) => {
        const { resorts } = this.state;
        if (resorts) {
            const boundedResorts = resorts.filter((resort) => zoomedResorts.indexOf(resort.resortId) > -1);
            this.setState({
                mapSynchedResorts: boundedResorts,
                totalResorts: boundedResorts.length,
            });
        }
    };

    private fetchNextItems = () => {
        if (this.buttonDOM?.blur) {
            this.buttonDOM.blur();
        }
        const { options } = this.props;
        const { toIndex } = this.state;
        const nextFetchCount: number = +options.nextNumberOfResorts! || 5;
        const newToIndex: number = toIndex + nextFetchCount;
        this.setState(() => ({
            infiniteLoading: false,
            toIndex: newToIndex,
        }));
    };

    private renderUnvirtualizedList({ childrenArray, slicedResorts, displayTypeChild }: { childrenArray: JSX.Element[]; slicedResorts: Resort[]; displayTypeChild: string }) {
        return slicedResorts.map((resort, index) =>
            this.renderLocationSearchPanel({
                index,
                childrenArray,
                elementsArray: slicedResorts,
                displayTypeChild,
            })
        );
    }

    private renderLocationSearchPanel({
        index,
        childrenArray,
        elementsArray: slicedResorts,
        displayTypeChild,
    }: {
        index: number;
        childrenArray: JSX.Element[];
        elementsArray: Resort[];
        displayTypeChild: string;
    }) {
        const {
            dynamicFilter,
            context: { currentLocale, site },
            options,
            children,
            userInterfaceState: { resultLayoutViewOptions },
        } = this.props;
        const { iconColor, iconToRight } = options;
        const { amenityCodes, zoomedResorts } = this.state;
        const { resultLayoutDisplayType: displayGridListMap } = resultLayoutViewOptions || {};
        const localizedDcOptions: LocalizedDcOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedOptions || [] });
        const rateTypeId: number | undefined = localizedDcOptions?.rateTypes?.length ? localizedDcOptions.rateTypes[0].value : dynamicFilter.rateType?.rateTypeId;
        const unitBookUri: string = options.unitPageUri;
        const resort = slicedResorts[index];
        const MoreIcon = options.iconPropertiesForMore && this.renderToggleIcon(options.iconPropertiesForMore);
        const LessIcon = options.iconPropertiesForLess && this.renderToggleIcon(options.iconPropertiesForLess);
        const showMoreIcon =
            options && options.plusIcon && !options.iconPropertiesForMore ? (
                <FontAwesome name="plus" className={`${iconToRight ? "ml-2" : "mr-1"} ${fontColorPicker(iconColor)}`} style={{ color: iconColor?.includes("rgba") ? iconColor : undefined }} />
            ) : (
                MoreIcon
            );
        const showLessIcon =
            options && options.plusIcon && !options.iconPropertiesForLess ? (
                <FontAwesome name="minus" className={`${iconToRight ? "ml-2" : "mr-1"} ${fontColorPicker(iconColor)}`} style={{ color: iconColor?.includes("rgba") ? iconColor : undefined }} />
            ) : (
                LessIcon
            );
        const childrenWithProps = React.Children.map(childrenArray, (child) =>
            React.cloneElement((child as React.ReactElement<any>).props.children, {
                rateTypeId,
                unitBookUri,
                dynamicFilter,
                resort,
                key: resort?.resortId || generateRandomKey(),
                amenityCodes: amenityCodes || [],
                accommodationType: resort?.curatedAccommodation,
                useCrpProps: true,
            })
        );
        const uniqueId = `resort_${resort?.resortId}`;
        const loadAccommodationsButton = (
            <div className="location-search-button-wrap">
                <Button onClick={() => this.toggleTypes(uniqueId)} className={`button location-search-button ${options.buttonLocation}`}>
                    {options?.showPriceOnButton && !this.state.toggleChildren.includes(uniqueId) && (
                        <div className="price-on-location-button">
                            <span className="price-label">{getI18nLocaleString(namespaceList.widgetTypeSearch, "priceLabel", currentLocale, site)}</span>
                            <span className="price">{`${resort?.curatedAccommodation?.priceInclusive}`}</span>
                            <span className="price-symbol">,- </span>
                        </div>
                    )}
                    {options.plusIcon && !options.iconToRight ? (this.state.toggleChildren.includes(uniqueId) ? showLessIcon : showMoreIcon) : undefined}
                    {this.getLoadAccommodationsButtonText({ localizedDcOptions, uniqueId, resort, currentLocale, site })}
                    {options.plusIcon && options.iconToRight ? (this.state.toggleChildren.includes(uniqueId) ? showLessIcon : showMoreIcon) : undefined}
                </Button>
            </div>
        );
        return (
            <ErrorBoundary key={index}>
                <div
                    id={uniqueId}
                    onMouseEnter={displayGridListMap === "list-view-map" || displayGridListMap === "grid-view-map" ? this.highlightMarker.bind(this, resort.resortId, "zoom-in") : undefined}
                    onMouseLeave={displayGridListMap === "list-view-map" || displayGridListMap === "grid-view-map" ? this.highlightMarker.bind(this, resort.resortId, "zoom-out") : undefined}
                    className={`location-search-wrapper__item ${zoomedResorts?.length && zoomedResorts.includes(resort.resortId) ? "fade-in" : "fade-out"} ${displayTypeChild}`}
                >
                    {childrenWithProps}
                    <div className="location-search-wrapper__button">
                        {options.loadAccommodations && options.buttonLocation === "above" && loadAccommodationsButton}
                        {this.state.toggleChildren.includes(uniqueId) &&
                            children?.map((child) =>
                                React.cloneElement(child.element as React.ReactElement<any>, {
                                    resort,
                                    key: resort?.resortId || generateRandomKey(),
                                    options: child.options,
                                })
                            )}
                        {options.loadAccommodations && options.buttonLocation === "below" && loadAccommodationsButton}
                    </div>
                </div>
            </ErrorBoundary>
        );
    }

    private static getMarkerLocationSearchPanel(resort: Resort, ind: number, stateHandler: LocationSearchContainerStateHandler) {
        const { childrenList, options, dynamicFilter } = stateHandler.props;
        const { showMap } = stateHandler.state;
        const unitBookUri: string = options.unitPageUri;
        const childrenWithProps = React.Children.map(childrenList, (child) =>
            React.cloneElement((child as React.ReactElement<any>).props.children, {
                resort,
                unitBookUri,
                dynamicFilter,
                key: resort ? resort.resortId : generateRandomKey(),
            })
        );
        if (showMap && childrenList.length && resort) {
            return <div key={ind}>{childrenWithProps}</div>;
        }
    }

    private handleSortingSelect = (sortingOption: string) => {
        const { mapSynchedResorts } = this.state;

        if (!mapSynchedResorts) {
            return;
        }
        const sortedResorts = JSON.parse(JSON.stringify(sortResults([...mapSynchedResorts!], sortingOption, undefined, true)));
        this.setState({
            resorts: sortedResorts,
            mapSynchedResorts: sortedResorts,
        });
    };

    private getLoadAccommodationsButtonText = (accommodationsButtonTextParams: {
        localizedDcOptions: LocalizedDcOptions | null;
        uniqueId: string;
        resort: Resort;
        currentLocale: CurrentLocale;
        site: Site & WithId;
    }) => {
        const { toggleChildren } = this.state;
        const { localizedDcOptions, uniqueId, resort, currentLocale, site } = accommodationsButtonTextParams;
        let accommodationsButtonText: string | undefined = "";
        accommodationsButtonText = toggleChildren.includes(uniqueId) ? localizedDcOptions?.sentenceForHide : localizedDcOptions?.sentenceForShow;
        if (!toggleChildren.includes(uniqueId) && resort?.accommodationTypes?.length === 1 && localizedDcOptions?.sentenceForShowSingular?.includes("[x]")) {
            accommodationsButtonText = localizedDcOptions.sentenceForShowSingular;
        }
        if (toggleChildren.includes(uniqueId) && resort?.accommodationTypes?.length === 1 && localizedDcOptions?.sentenceForHideSingular?.includes("[x]")) {
            accommodationsButtonText = localizedDcOptions.sentenceForHideSingular;
        }
        if (accommodationsButtonText?.includes("[x]")) {
            accommodationsButtonText = accommodationsButtonText?.replace(/\[x]/g, `${resort?.accommodationTypes?.length}`);
        }
        if (accommodationsButtonText?.includes("[a]")) {
            accommodationsButtonText = accommodationsButtonText?.replace(/\[a]/g, getI18nLocaleString(namespaceList.widgetAssetPublisher, "all", currentLocale, site).toLowerCase());
        }
        return accommodationsButtonText;
    };

    private highlightMarker = (resortId: number, zoomType: string) => {
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.onMouseOverOut,
            payload: {
                onMouseOverOut: zoomType === "zoom-in" ? resortId : undefined,
            },
        };
        this.props.dispatchAction(action);
        // this.handleDispatchLocation(accommodationTypes!);
    };

    private static handleDispatchDynamicMarkers(resorts: Resort[], isAllResultFetched: boolean, stateHandler: LocationSearchContainerStateHandler) {
        // ToDo: Add resort IDs to the markers when location search is present.
        const markers: MarkerDetails[] = resorts.length
            ? resorts.map((resort, ind) => ({
                  markerId: resort.resortId,
                  resourceId: resort.resortId,
                  position: {
                      lat: resort.resortLocation?.lat || 0,
                      lng: resort.resortLocation?.lon || 0,
                  },
                  markerDetails: LocationSearchContainerWidgetBase.getMarkerLocationSearchPanel(resort, ind, stateHandler),
                  title: resort.name,
                  showDetail: false,
              }))
            : [];
        const locationAction: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.dynamicMarkersIsFetchComplete,
            payload: {
                dynamicMarkers: markers,
                isAllResultFetched,
            },
        };
        stateHandler.props.dispatchAction(locationAction);
    }

    private static async fetchDetails(
        props: LocationSearchContainerProps,
        env: ApiCallOptions | undefined,
        fetchParameters: FetchParameters,
        tthis?: LocationSearchContainerWidgetBase
    ): Promise<FetchResult> {
        const { stayPeriodDef } = fetchParameters;
        const { mxtsApi } = props.context;
        const response: AvailabilityResult | undefined = props.availabilityState.availabilityResult;
        const resortGroup: ResortGroup | undefined = response?.response?.resortGroup;
        const resortIds: number[] | undefined = resortGroup?.map((resort) => resort.resortId);
        if (env) {
            const fetchedStayPeriodDefs: StayPeriodDef[] =
                stayPeriodDef.isIncluded && env
                    ? await mxtsApi.stayPeriodDefs(env, { stayPeriodDefIds: stayPeriodDef.stayPeriodDefIds }, undefined, tthis?.controller?.signal).then((result) => result.content)
                    : [];
            const resortAndAddresses: Array<ExtendedResort | Resort> | undefined = resortIds?.length
                ? await fetchResortsByResortIds({ resortIds, env, includeAddress: true, pageRequestSize: 75 }, mxtsApi)
                : undefined;

            const fetchedResorts = await Promise.all(
                (resortAndAddresses || []).map(async (resort: Resort | ExtendedResort) => {
                    const resortAddress = resort.address;
                    resort.city = resortAddress?.city || "";
                    resort.accommodationTypes = resortGroup?.find((resortDetails) => resort.resortId === resortDetails.resortId)?.accommodationTypes;
                    const sortedAccommodations: undefined | Document[] =
                        resort.accommodationTypes &&
                        sortResults([...resort.accommodationTypes], Sort[props.options.fetchLatestArrivalDate ? Sort.ascendingArrivalDate : Sort.lowToHighPrice], undefined, true);
                    const curatedAccommodation = sortedAccommodations?.[0];
                    const fetchedResourceDetails = curatedAccommodation?.resourceId
                        ? (await mxtsApi.resources(env!, { size: 50, resourceIds: [curatedAccommodation.resourceId] }, undefined, tthis?.controller?.signal))?.content?.[0]
                        : null;
                    const stayPeriodDef: StayPeriodDef | null = curatedAccommodation?.stayPeriodDefId
                        ? stayPeriodDefById(fetchedStayPeriodDefs ? fetchedStayPeriodDefs : [], curatedAccommodation.stayPeriodDefId)
                        : null;
                    if (curatedAccommodation) {
                        resort.curatedAccommodation = {
                            ...curatedAccommodation,
                            ...fetchedResourceDetails,
                            stayPeriodDefName: stayPeriodDef?.name || "",
                            stayPeriodDefCode: stayPeriodDef?.code || "",
                        } as AccommodationType;
                    }
                    return resort;
                })
            );
            return {
                resorts: fetchedResorts,
                stayPeriodDefs: fetchedStayPeriodDefs,
                addresses: (resortAndAddresses?.map((resort) => resort.address).filter((address) => !!address) as Address[]) || [],
            };
        }
        return { resorts: [], stayPeriodDefs: [], addresses: [] };
    }

    private static async updateLocations(
        props: LocationSearchContainerProps,
        fetchAvailability = false,
        stateHandler: LocationSearchContainerStateHandler,
        tthis?: LocationSearchContainerWidgetBase
    ): Promise<void> {
        const env: ApiCallOptions | undefined = props.availabilityState.env;
        const nextResponse: AvailabilityResult | undefined = props.availabilityState.availabilityResult;
        const stayPeriodDefIds: Facet = nextResponse?.response?.stayPeriodDefs || [];
        const nextResorts: number[] = nextResponse?.response?.resortGroup?.map((resort) => resort.resortId) || [];
        if (nextResponse?.response?.resortGroup?.length === 0) {
            await this.errorHandler(stateHandler);
        }

        const fetchParameters: FetchParameters = {
            stayPeriodDef: {
                stayPeriodDefIds,
                isIncluded: true,
            },
            address: {
                isIncluded: true,
            },
        };

        let allResorts: Resort[] = [];
        if (nextResorts?.length && fetchAvailability) {
            const { resorts, stayPeriodDefs, addresses } = await LocationSearchContainerWidgetBase.fetchDetails(props, env, fetchParameters, tthis);
            // Applying sorting on the results
            allResorts = sortResults([...resorts], stateHandler.state.sortingOption, undefined, true);
            let locationsPrice;
            if (allResorts?.length) {
                locationsPrice = LocationSearchContainerWidgetBase.setMinAndMaxResortsPrice(allResorts);
            }
            stateHandler.setState(
                {
                    isLoading: false,
                    totalResorts: resorts.length,
                    mapSynchedResorts: allResorts,
                    zoomedResorts: (allResorts || []).map((resort) => resort.resortId),
                    resorts,
                    stayPeriodDefs,
                    addresses,
                    nextResorts,
                    minPrice: locationsPrice ? locationsPrice.minprice : stateHandler.state.minPrice,
                    maxPrice: locationsPrice ? locationsPrice.maxprice : stateHandler.state.maxPrice,
                },
                () => {
                    const resultLayoutDisplayType = props.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType;
                    if (resultLayoutDisplayType === "list-view-map" || resultLayoutDisplayType === "grid-view-map") {
                        LocationSearchContainerWidgetBase.handleDispatchDynamicMarkers(stateHandler.state.resorts!, true, stateHandler);
                    }
                }
            );
            return;
        }
        // If certain filters are applied, that don't need to get new Availability
        const { resorts } = stateHandler.state;
        let filteredResorts: Resort[] = [];
        if (props.dynamicFilter.selectedDirectSearchId) {
            const selectedDirectSearchId: number = props.dynamicFilter.selectedDirectSearchId;
            filteredResorts = resorts!.filter((resort) => resort.resortId === selectedDirectSearchId);
        }
        if (props.dynamicFilter.directSearchInput) {
            const directSearchInput: string = props.dynamicFilter.directSearchInput;
            filteredResorts = resorts!.filter((resort) => resort.name.toLowerCase().includes(directSearchInput.toLowerCase()));
        }
        // ToDo: Add MinPrice and MaxPrice to Resorts
        if (props.dynamicFilter.minprice && props.dynamicFilter.maxprice) {
            filteredResorts =
                resorts?.filter((resort: any) => {
                    const resortPrice = resort.price ? resort.price : resort.nightPrice ? resort.nightPrice : undefined;
                    if (resortPrice && props.dynamicFilter.minprice && props.dynamicFilter.maxprice) {
                        return resortPrice >= props.dynamicFilter.minprice && resortPrice <= props.dynamicFilter.maxprice;
                    }
                }) || [];
        }
        let filteredResortsPrice;
        // Set min and max price out of the filtered types and update state
        if (filteredResorts.length) {
            filteredResortsPrice = LocationSearchContainerWidgetBase.setMinAndMaxResortsPrice(filteredResorts);
            stateHandler.setState(
                {
                    mapSynchedResorts: filteredResorts?.length ? filteredResorts : resorts,
                    minPrice: filteredResortsPrice ? filteredResortsPrice.minprice : stateHandler.state.minPrice,
                    maxPrice: filteredResortsPrice ? filteredResortsPrice.maxprice : stateHandler.state.maxPrice,
                },
                () => {
                    const resultLayoutDisplayType = props.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType;
                    if (resultLayoutDisplayType === "list-view-map" || resultLayoutDisplayType === "grid-view-map") {
                        LocationSearchContainerWidgetBase.handleDispatchDynamicMarkers(filteredResorts, true, stateHandler);
                    }
                }
            );
        }
    }

    private static async errorHandler(stateHandler: LocationSearchContainerStateHandler): Promise<void> {
        const { alerts, currentLocale, site } = stateHandler.props.context;
        const webContent: (CmsApiWebContent & WithId) | null = await LocationSearchContainerWidgetBase.getNoDataFoundContent(stateHandler.props);
        const template: JSX.Element[] | null = await LocationSearchContainerWidgetBase.getNoDataFoundTemplate(stateHandler.props);
        if (webContent || template) {
            alerts.push({
                color: "danger",
                message: getI18nLocaleString(namespaceList.widgetSearchfacet, "noDataFound", currentLocale, site),
            });
        }
        stateHandler.setState(
            {
                webContent: webContent || undefined,
                template: template || undefined,
                errorFetchingResorts: "error",
                isLoading: false,
                resorts: [],
                mapSynchedResorts: [],
                totalResorts: 0,
            },
            () => {
                const resultLayoutDisplayType = stateHandler.props.userInterfaceState.resultLayoutViewOptions?.resultLayoutDisplayType;
                if (resultLayoutDisplayType === "list-view-map" || resultLayoutDisplayType === "grid-view-map") {
                    LocationSearchContainerWidgetBase.handleDispatchDynamicMarkers(stateHandler.state.resorts!, true, stateHandler);
                }
            }
        );
    }

    private static async getNoDataFoundContent(props: LocationSearchContainerProps): Promise<(CmsApiWebContent & WithId) | null> {
        const { webContentId } = props.options;
        if (webContentId) {
            return props.context.cmsApi.webContentApi.findById({ id: webContentId });
        }
        return null;
    }

    private static async getNoDataFoundTemplate(props: LocationSearchContainerProps): Promise<JSX.Element[] | null> {
        const { templateId } = props.options;
        if (templateId) {
            const template: (Template & WithId) | null = await props.context.cmsApi.templateApi.findById({ id: templateId });
            if (template) {
                return await renderPageWidgets(template.root, props.context);
            }
        }
        return null;
    }

    private static async handleAmenities(amenityIds: string[], stateHandler: LocationSearchContainerStateHandler, tthis?: LocationSearchContainerWidgetBase) {
        const { mxtsApi } = stateHandler.props.context;
        const apiCallOptions: ApiCallOptions = await getMxtsEnv(stateHandler.props.context);
        if (amenityIds) {
            // Amenity codes are absent in dynamicFilter, fetching them to pass them towards BE
            const amenityCodes: string[] = [];
            if (amenityIds.length) {
                const fetchedAmenities: Amenity[] = await mxtsApi.amenities(apiCallOptions, { identifier: amenityIds.join(",") }, undefined, tthis?.controller?.signal).then((am) => am.content);
                if (fetchedAmenities) {
                    fetchedAmenities.forEach((element: Amenity) => {
                        amenityCodes.push(element.identifier);
                    });
                }
            }
            stateHandler.setState({ amenityCodes });
        }
    }

    private static getAction(filter: dynamicFilterType, payload: DynamicFilter): FilterChangeAction {
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter,
            payload,
        };
        return action;
    }

    // eslint-disable-next-line max-lines-per-function
    private static async populateDynamicFilterWithPreSelectedFilters(props: LocationSearchContainerProps): Promise<void> {
        const amenityIds: string[] | undefined = props.options.amenities?.map((amenity) => amenity.value.toString());
        const specialCodes: string[] | undefined = props.options.specialCodes?.map((code) => code.value);
        const resortId: string | undefined = props.options.resortId;
        const regionId: string | undefined = props.options.regionId;
        const minArrivalDate: string | undefined = props.options.minArrivalDate ? moment(props.options.minArrivalDate).format(DATE_FORMAT.ELASTIC) : undefined;
        const maxArrivalDate: string | undefined = props.options.maxArrivalDate ? moment(props.options.maxArrivalDate).format(DATE_FORMAT.ELASTIC) : undefined;
        const accoKinds: number[] | undefined = props.options.accoKinds?.map((accoKind) => +accoKind.value);
        const {
            dispatchAction,
            context: { currentLocale, site, mxtsApi },
            dynamicFilter: { rateType: { rateTypeId } = {}, distributionChannel: { distributionChannelId } = {}, stay },
            options,
        } = props;
        const env: ApiCallOptions = await getMxtsEnv(props.context, currentLocale.code);
        const { holidayCode, minCapacity, showResortAmenitiesCount } = options;
        const filter: DynamicFilter = {};
        if (amenityIds?.length) {
            const dynamicAmenities: string[] = props.dynamicFilter.amenities?.filter((am) => amenityIds.indexOf(am) === -1) || [];
            filter.amenities = [...dynamicAmenities, ...amenityIds];
        }

        if (specialCodes?.length) {
            filter.specialcode = specialCodes;
        }

        if (regionId) {
            filter.regionIds = [+regionId];
        }

        if (resortId) {
            filter.resortids = [+resortId];
        }

        if (accoKinds?.length) {
            filter.accokindids = accoKinds;
        }

        if (minArrivalDate) {
            filter.minimumArrivalDate = minArrivalDate;
        }
        if (maxArrivalDate) {
            filter.maximumArrivalDate = maxArrivalDate;
        }
        if (minCapacity) {
            filter.minCapacity = minCapacity;
        }
        if (showResortAmenitiesCount) {
            filter.showResortAmenitiesCount = showResortAmenitiesCount;
        }
        const { defaultStay, stayFilter } = await getDefaultStayCode(env, mxtsApi, filter, props.options?.defaultStay, stay);
        const stayPeriodDefFilters = await getStayPeriodDefFilters(mxtsApi, { defaultStay, env, dynamicFilter: props.dynamicFilter, holidayCode });
        Object.entries(stayPeriodDefFilters).forEach(([key, value]: ["stayperioddefid" | "stayHolidayPeriodDefId", number]) => {
            filter[key] = value;
        });

        const filterType: dynamicFilterType.addPreselectedFilters = dynamicFilterType.addPreselectedFilters;
        const localizedDcOptions: LocalizedDcOptions | null = getLocalizedContent({ site, currentLocale, localizedContent: props.options.localizedOptions || [] });
        // If widget options has DC already selected, dispatching that DC

        const newRateTypeId: number | undefined = localizedDcOptions?.rateTypes?.length ? localizedDcOptions.rateTypes?.[0].value : rateTypeId;
        const payload: DynamicFilter = { ...props.dynamicFilter, ...filter, ...stayFilter, shouldFetchResorts: true };
        if (newRateTypeId && newRateTypeId !== rateTypeId) {
            const rateType = await mxtsApi
                .rates(env, {
                    rateTypeId: newRateTypeId,
                })
                .then((rates) => rates?.content?.[0])
                .catch((error: Error) => {
                    props.context.logger.error(error.message);
                    return undefined;
                });
            if (rateType) {
                payload.rateType = { rateTypeId: rateType.rateTypeId, code: rateType.code };
            }
        }
        let localizedDcId = localizedDcOptions?.distributionChannelId;
        if (isMobileDeviceDetected() && localizedDcOptions?.mobileDCId) {
            localizedDcId = localizedDcOptions?.mobileDCId;
        }
        const newDistributionChannelId = localizedDcId || distributionChannelId;
        if (newDistributionChannelId && newDistributionChannelId !== distributionChannelId) {
            const distributionChannel = await mxtsApi.distributionChannel(env, {}, [{ key: "dcId", value: newDistributionChannelId }]).catch((error: Error) => {
                props.context.logger.error(error.message);
                return undefined;
            });
            if (distributionChannel) {
                payload.distributionChannel = { distributionChannelId: distributionChannel.distributionChannelId, code: distributionChannel.code };
            }
        }
        if (props.options.fetchLatestArrivalDate) {
            payload.fetchLatestArrivalDate = "ARRIVAL_DATE";
        }
        const action: FilterChangeAction = LocationSearchContainerWidgetBase.getAction(filterType, payload);
        dispatchAction(action);
    }
}

function mapStateToProps(state: State): LocationSearchContainerStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
        userInterfaceState: state.userInterfaceState,
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction | AvailabilityAction | AmenitiesAction>): LocationSearchContainerDispatchProps {
    return { dispatchAction: dispatch };
}

// eslint-disable-next-line max-len
const LocationSearchContainer = connect<LocationSearchContainerStoreProps, LocationSearchContainerDispatchProps>(mapStateToProps, mapDispatchToProps)(LocationSearchContainerWidgetBase);

export const LocationSearchContainerWidget = withVirtualization(wrapProps<LocationSearchContainerBaseProps>(LocationSearchContainer));
