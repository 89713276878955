import * as Icons from "../../page/icons";
import * as React from "react";

import { AlternativeColumnOptions, colSelectOptions } from "../../page/columns";
import { FormSpec, IconColoring, localized, multiSelectStylePicker, templateSpec } from "../../../form-specs";
import { LocalizedContentBase, Typesearch } from "@maxxton/cms-api";
import { LocationSearchContainerWidget, LocationSearchContainerWidgetBase } from "./LocationSearchContainerWidget";
import { NumberMultiSelectOption, StringMultiSelectOption } from "../../mxts/selectOption.types";
import { PageWidgetSpec, ResultOptions, Widget, isPageWidget, renderPageWidgets, reportWidgetRenderError } from "../../widget";
import { Resort, getDistributionChannelLazyLoadPage, rateTypeOptions } from "../../mxts";
import { amenityMultiSelect, defaultStayAutoComplete, generateRandomKey, minCapacityField, multiSelectSpecials, resortAutocomplete } from "../../../components/utils";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { DefaultIcons } from "../../page/assetpublisher/index";
import { ElementsUtil } from "../../../utils/elements.util";
import { IconSelect } from "../../page/assetpublisher";
import { MxtsApi } from "@maxxton/cms-mxts-api";
import { ResultStyleSelector } from "../../../utils/constants";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import { isServerSide } from "../../../utils/generic.util";
import { multiSelectAccoKinds } from "../../mxts/typesearch";
import namespaceList from "../../../i18n/namespaceList";
import { regionsAutocomplete } from "../../../inputSpecs";
import { resultsPanelSpec } from "../../../form-specs/models/resultsPanel";
import stayHolidayPeriodDefAutocomplete from "../../../inputSpecs/stayHolidayPeriodDefAutocomplete";
import { webcontentSpecWithoutPreview } from "../../../form-specs/models/autocompleteWebContent";

/* jscpd:ignore-start */

export interface WidgetOptions extends AlternativeColumnOptions {
    typeOfDisplay: string;
    resultsPanelList: string;
    multipleLayouts: boolean;
    resultsPanelGrid: string;
    resultsPanelTablet: string;
    resultsPanelMobile: string;
    styleIds?: string[];
    showLoadMoreButton: boolean;
    enableVirtualization?: boolean;
    defaultNumberOfResorts?: number;
    nextNumberOfResorts?: number;
    displayType: string;
    webContentId?: string | null;
    templateId?: string | null;
    amenities?: NumberMultiSelectOption[];
    resortId?: string;
    specialCodes?: StringMultiSelectOption[];
    unitPageUri: string;
    localizedOptions?: LocalizedDcOptions[];
    minArrivalDate?: Date;
    maxArrivalDate?: Date;
    fetchLatestArrivalDate: boolean;
    regionId?: string;
    accoKinds?: NumberMultiSelectOption[];
    defaultStay?: number;
    resultStyleSelector?: string;
    loadAccommodations: boolean;
    buttonLocation: string;
    holidayCode?: string;
    minCapacity?: number;
    plusIcon: boolean;
    iconPropertiesForLess: string;
    iconPropertiesForMore: string;
    iconColor: IconColoring;
    iconToRight: boolean;
    showPriceOnButton?: boolean;
    showResortAmenitiesCount?: boolean;
}

export interface LocalizedDcOptions extends LocalizedContentBase {
    rateTypes?: NumberMultiSelectOption[];
    distributionChannelId?: number;
    sentenceForShow?: string;
    sentenceForHide?: string;
    sentenceForShowSingular?: string;
    sentenceForHideSingular?: string;
    mobileDCId?: number;
}

const TARGETS = ["price"];

const iconList: IconSelect[] = Object.keys(Icons.default).map((key: string) => ({ label: (Icons.default as DefaultIcons)[key], value: key }));

/* jscpd:ignore-start */
const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "locationsearch-price-widget",
    name: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "locationSearchContainerWidget"),
    pluralName: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "locationSearchContainerWidget"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "general"),
                    properties: [
                        [
                            {
                                variable: "resultsPanelList",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "resultsPanelList"),
                                type: "autocomplete",
                                refType: resultsPanelSpec,
                            },
                            {
                                variable: "resultsPanelGrid",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "resultsPanelGrid"),
                                type: "autocomplete",
                                refType: resultsPanelSpec,
                            },
                            {
                                variable: "multipleLayouts",
                                label: getI18nLocaleObject(namespaceList.displayGridListMapWidget, "multipleLayouts"),
                                type: "checkbox",
                            },
                            {
                                variable: "resultsPanelMobile",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "resultsPanelMobile"),
                                type: "autocomplete",
                                refType: resultsPanelSpec,
                                visible: (options: WidgetOptions) => options.multipleLayouts,
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForCRPMobileLayout"),
                            },
                            {
                                variable: "resultsPanelTablet",
                                label: getI18nLocaleObject(namespaceList.dynamicContainer, "resultsPanelTablet"),
                                type: "autocomplete",
                                refType: resultsPanelSpec,
                                visible: (options: WidgetOptions) => options.multipleLayouts,
                                placeholder: getI18nLocaleObject(namespaceList.admin, "placeholderForCRPTabletLayout"),
                            },
                            {
                                variable: "unitPageUri",
                                label: getI18nLocaleObject(namespaceList.widgetUnitSearch, "unitBookUri"),
                                type: "text",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "typesGridDisplay"),
                    properties: [
                        [
                            {
                                variable: "columns",
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.admin, "columnsMobile"),
                                default: "",
                                optionList: colSelectOptions,
                            },
                            {
                                variable: "columnsResp",
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.admin, "columnsResponsive"),
                                default: "",
                                optionList: colSelectOptions,
                            },
                            {
                                variable: "columnsTab",
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.admin, "columnsTablet"),
                                default: "",
                                optionList: colSelectOptions,
                            },
                            {
                                variable: "columnsDesk",
                                type: "select",
                                label: getI18nLocaleObject(namespaceList.admin, "columnsDesktop"),
                                default: "",
                                optionList: colSelectOptions,
                            },
                        ],
                    ],
                    visible: (options: WidgetOptions) => !!options.resultsPanelGrid,
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "display"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "descriptionUnitSearchDisplay"),
                            },
                            {
                                variable: "resultStyleSelector",
                                label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultStyleSelector"),
                                type: "radioImage",
                                default: ResultStyleSelector.DEFAULT,
                                radioOptions: [
                                    {
                                        value: ResultStyleSelector.DISPLAY_STYLE_HIDE,
                                        label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountHideLabel"),
                                        element: () => ElementsUtil.svgElement(getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountHide")),
                                    },
                                    {
                                        value: ResultStyleSelector.DEFAULT,
                                        label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountLabelDefault"),
                                        element: () => ElementsUtil.svgElement(getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountDefault")),
                                    },
                                    {
                                        value: ResultStyleSelector.SHOWING_X_RESULT,
                                        label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountLabel1"),
                                        element: () => ElementsUtil.svgElement(getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountOption1")),
                                    },
                                    {
                                        value: ResultStyleSelector.X_RESULT,
                                        label: getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountLabel2"),
                                        element: () => ElementsUtil.svgElement(getI18nLocaleObject(namespaceList.widgetResultCount, "resultCountOption2")),
                                    },
                                ],
                            },
                            {
                                variable: "defaultNumberOfResorts",
                                label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "defaultNumberOfResorts"),
                                type: "text",
                            },
                            {
                                variable: "nextNumberOfResorts",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "nextNumberOfTypes"),
                                type: "text",
                            },
                            {
                                variable: "showLoadMoreButton",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showLoadMoreButton"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "enableVirtualization",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "enableVirtualization"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "loadAccommodations",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "loadAccommodations"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                variable: "buttonLocation",
                                label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "buttonLocation"),
                                type: "select",
                                optionList: [
                                    {
                                        label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "aboveResults"),
                                        value: "above",
                                    },
                                    {
                                        label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "belowResults"),
                                        value: "below",
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.loadAccommodations,
                                placeholder: getI18nLocaleObject(namespaceList.amenitiesWidget, "iconSetPlaceholder"),
                            },
                            {
                                variable: "showPriceOnButton",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showPriceOnbutton"),
                                default: false,
                                type: "checkbox",
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "showHideTextInfo"),
                                visible: (options: WidgetOptions) => options.loadAccommodations,
                            },
                            localized({
                                variable: "localizedOptions",
                                tabContent: [
                                    {
                                        variable: "sentenceForShow",
                                        label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "sentenceForShow"),
                                        type: "text",
                                    },
                                    {
                                        variable: "sentenceForHide",
                                        label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "sentenceForHide"),
                                        type: "text",
                                    },
                                    {
                                        variable: "sentenceForShowSingular",
                                        label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "sentenceForShowSingular"),
                                        type: "text",
                                    },
                                    {
                                        variable: "sentenceForHideSingular",
                                        label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "sentenceForHideSingular"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => options.loadAccommodations,
                            }),
                            {
                                variable: "showResortAmenitiesCount",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "showAmenitiesCount"),
                                default: false,
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "presetOptions"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "descriptionForPresetOptions"),
                            },
                            amenityMultiSelect("amenities"),
                            multiSelectAccoKinds("accoKinds", getI18nLocaleObject(namespaceList.widgetTypeSearch, "accoKinds")),
                            multiSelectSpecials("specialCodes", getI18nLocaleObject(namespaceList.widgetUnitSearch, "specialCodes")),
                            regionsAutocomplete("regionId"),
                            {
                                variable: "minArrivalDate",
                                type: "date",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "minArrivalDate"),
                            },
                            {
                                variable: "maxArrivalDate",
                                type: "date",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "maxArrivalDate"),
                            },
                            resortAutocomplete("resortId"),
                            defaultStayAutoComplete("defaultStay"),
                            { ...stayHolidayPeriodDefAutocomplete("holidayCode") },
                            minCapacityField("minCapacity"),
                            localized({
                                variable: "localizedOptions",
                                tabContent: [
                                    {
                                        variable: "distributionChannelId",
                                        label: getI18nLocaleObject(namespaceList.widgetUnitSearch, "distributionChannel"),
                                        type: "lazyLoadAutoComplete",
                                        lazyLoadOptions: (page: number, searchQuery: string, id) => getDistributionChannelLazyLoadPage(page, searchQuery, id ? [id] : undefined, MxtsApi),
                                        placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "dcPlaceholder"),
                                    },
                                    {
                                        variable: "mobileDCId",
                                        label: getI18nLocaleObject(namespaceList.widgetUnitSearch, "distributionChannelMobile"),
                                        type: "lazyLoadAutoComplete",
                                        lazyLoadOptions: (page: number, searchQuery: string, id) => getDistributionChannelLazyLoadPage(page, searchQuery, id ? [id] : undefined, MxtsApi),
                                        placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "dcPlaceholder"),
                                    },
                                    {
                                        variable: "rateTypes",
                                        label: getI18nLocaleObject(namespaceList.admin, "rateTypes"),
                                        type: "multiselect",
                                        optionList: ({ item, locale }: { item?: any; locale?: string }) => (item && locale ? rateTypeOptions(MxtsApi, item, locale) : []),
                                        visible: (item?: LocalizedDcOptions) => !!item?.distributionChannelId,
                                    },
                                ],
                            }),
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),

                            {
                                // plusIcon variable will be use as show an icon
                                label: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "showIconOnButton"),
                                variable: "plusIcon",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.loadAccommodations,
                            },
                            {
                                variable: "iconPropertiesForLess",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "iconForLess"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.plusIcon,
                                optionList: iconList,
                            },
                            {
                                variable: "iconPropertiesForLess",
                                type: "icons",
                                visible: (options: WidgetOptions) => options.plusIcon,
                            },
                            {
                                variable: "iconPropertiesForMore",
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "iconForMore"),
                                type: "select",
                                visible: (options: WidgetOptions) => options.plusIcon,
                                optionList: iconList,
                            },
                            {
                                variable: "iconPropertiesForMore",
                                type: "icons",
                                visible: (options: WidgetOptions) => options.plusIcon,
                            },
                            {
                                variable: "iconColor",
                                label: getI18nLocaleObject(namespaceList.pluginSearchResultsPanel, "iconColor"),
                                type: "dual-color",
                                default: "default",
                                visible: (options: WidgetOptions) => options.plusIcon,
                            },
                            {
                                label: getI18nLocaleObject(namespaceList.widgetRevealer, "iconToRight"),
                                variable: "iconToRight",
                                type: "checkbox",
                                visible: (options: WidgetOptions) => options.plusIcon,
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.widgetTypeSearch, "extra"),
                    properties: [
                        [
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "extraInfoDescription"),
                            },
                            {
                                variable: "webContentId",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundWebcontent"),
                                type: "autocomplete",
                                refType: webcontentSpecWithoutPreview,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundContentPlaceholder"),
                            },
                            {
                                variable: "templateId",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplate"),
                                type: "autocomplete",
                                refType: templateSpec,
                                placeholder: getI18nLocaleObject(namespaceList.widgetTypeSearch, "noAccoTypeFoundTemplatePlaceholder"),
                            },
                            {
                                variable: "fetchLatestArrivalDate",
                                label: getI18nLocaleObject(namespaceList.widgetTypeSearch, "sortOnArrivalDate"),
                                default: false,
                                type: "checkbox",
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};
/* jscpd:ignore-end */

/* jscpd:ignore-start */
export const locationSearchContainerWidget: PageWidgetSpec<WidgetOptions> = {
    id: "locationsearch-container-widget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.pluginLocationSearchContainer, "locationSearchContainerWidget"),
    description: getI18nLocaleObject(namespaceList.pluginTypesearchContainer, "typesearchContainerWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        typeOfDisplay: "",
        multipleLayouts: false,
        resultsPanelList: "",
        resultsPanelGrid: "",
        resultsPanelTablet: "",
        resultsPanelMobile: "",
        styleIds: [],
        showLoadMoreButton: false,
        defaultNumberOfResorts: undefined,
        nextNumberOfResorts: undefined,
        displayType: "displayColumns",
        unitPageUri: "",
        resultStyleSelector: ResultStyleSelector.DEFAULT,
        loadAccommodations: false,
        fetchLatestArrivalDate: false,
        buttonLocation: "below",
        plusIcon: false,
        iconPropertiesForLess: "",
        iconPropertiesForMore: "",
        iconColor: "color-brand",
        iconToRight: false,
    }),
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties) {
        const { resultsPanelList, resultsPanelGrid, resultsPanelTablet, resultsPanelMobile, styleIds } = widget.options;
        const typeLayoutList: Typesearch | null = resultsPanelList ? await context.cmsApi.typesearchApi.findById({ id: resultsPanelList }) : null;
        const typeLayoutGrid: Typesearch | null = resultsPanelGrid ? await context.cmsApi.typesearchApi.findById({ id: resultsPanelGrid }) : null;
        const typeLayoutTablet: Typesearch | null = resultsPanelTablet ? await context.cmsApi.typesearchApi.findById({ id: resultsPanelTablet }) : null;
        const typeLayoutMobile: Typesearch | null = resultsPanelMobile ? await context.cmsApi.typesearchApi.findById({ id: resultsPanelMobile }) : null;
        if (typeLayoutList === null && typeLayoutGrid === null && typeLayoutTablet === null && typeLayoutMobile === null) {
            throw new Error("No TypeLayout selected");
        }
        const classNames = findMultiSelectStyleClassNames(context.theme, TARGETS, styleIds as any[]);
        const childrenList = typeLayoutList ? await renderPageWidgets(typeLayoutList.root, context, undefined, {}) : [];
        const childrenGrid = typeLayoutGrid ? await renderPageWidgets(typeLayoutGrid.root, context, undefined, {}) : [];
        const childrenMobile = typeLayoutMobile ? await renderPageWidgets(typeLayoutMobile.root, context, undefined, {}) : [];
        const childrenTablet = typeLayoutTablet ? await renderPageWidgets(typeLayoutTablet.root, context, undefined, {}) : [];
        const children = (
            await Promise.all(
                widget.children.map((child, index) => {
                    const childSpec = child.spec;
                    if (isPageWidget(childSpec)) {
                        return childSpec
                            .render(child, context, undefined, {})
                            .then((element) => ({ element, options: child.options }))
                            .catch((err) => {
                                reportWidgetRenderError(widget, err, childSpec, context);
                                return null;
                            });
                    }
                    throw new TypeError("Expected child widgets to be page widgets");
                })
            )
        ).filter((child) => !!child) as Array<{ element: JSX.Element; options: WidgetOptions }>;

        let warmupState: any;
        if (isServerSide()) {
            warmupState = await LocationSearchContainerWidgetBase.warmupCache({
                availabilityState: {},
                classNames,
                options: widget.options,
                children,
                childrenList,
                childrenGrid,
                childrenMobile,
                childrenTablet,
                context,
                dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
                dispatchAction: context.reduxStore.store.dispatch,
                userInterfaceState: {},
            });

            if (warmupState.mapSynchedResorts) {
                const dynamicFilter = context.reduxStore.store.getState().dynamicFilter;
                const slicedResorts = warmupState.mapSynchedResorts?.slice(warmupState.fromIndex, warmupState.toIndex) || [];
                await Promise.all(
                    slicedResorts.map(async (resort: Resort) => {
                        const resortProps: ResultOptions = {
                            rateTypeId: dynamicFilter.rateType?.rateTypeId,
                            unitBookUri: (widget.options as any).unitBookUri,
                            dynamicFilter,
                            resort,
                            key: resort?.resortId || generateRandomKey(),
                            amenityCodes: warmupState.amenityCodes || [],
                            accommodationType: resort?.curatedAccommodation,
                            useCrpProps: true,
                        };
                        await Promise.all([
                            typeLayoutList ? await renderPageWidgets(typeLayoutList.root, context, undefined, resortProps) : Promise.resolve([]),
                            typeLayoutGrid ? await renderPageWidgets(typeLayoutGrid.root, context, undefined, resortProps) : Promise.resolve([]),
                            typeLayoutMobile ? await renderPageWidgets(typeLayoutMobile.root, context, undefined, resortProps) : Promise.resolve([]),
                            typeLayoutTablet ? await renderPageWidgets(typeLayoutTablet.root, context, undefined, resortProps) : Promise.resolve([]),
                        ]);
                    })
                );
            }
        }

        /* jscpd:ignore-end */
        return (
            <LocationSearchContainerWidget
                classNames={classNames}
                options={widget.options}
                context={context}
                children={children}
                childrenList={childrenList}
                childrenGrid={childrenGrid}
                childrenMobile={childrenMobile}
                childrenTablet={childrenTablet}
                warmupState={warmupState}
            />
        );
    },
    async initDefaultFilter(widget, context, dispatcher): Promise<void> {
        await LocationSearchContainerWidgetBase.initDefaultFilter({
            options: widget.options,
            context,
            dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
            dispatchAction: dispatcher,
        } as any);
    },
};
